import Swiper from 'swiper/swiper-bundle.min';
import anime from 'animejs';
import $ from 'jquery';
import {TweenMax} from "gsap";
import {pJS} from "particles.js";
import * as Masonry from 'masonry-layout';
import * as imagesLoaded from 'imagesloaded';

export function index(){
	
	if(document.getElementById('viewdata')){
		particlesJS.load('viewdata', '/include/json/particlesjs-config.json');
		let
			firstview = document.getElementById('fvbg'),
			follower = document.getElementById('follower'),
			fWidth = 40,
			delay = 8,
			mouseX = 0,
			mouseY = 0,
			posX = 0,
			posY = 0;

		TweenMax.to({}, .01, {
			repeat: -1,
			onRepeat: function() {
				posX += (mouseX - posX) / delay;
				posY += (mouseY - posY) / delay;
				TweenMax.set(firstview, {
					css: {    
						left: Math.floor((posX - (fWidth / 2)) / 50),
						top: Math.floor((posY - (fWidth / 2)) / 50)
					}
				});
				TweenMax.set(ball, {
					css: {    
						left: Math.floor((posX - (fWidth / 2)) / 20),
						top: Math.floor((posY - (fWidth / 2)) / 20)
					}
				});
			}
		});

		document.body.addEventListener("mousemove", function(e){
			mouseX = e.clientX;
			mouseY = e.clientY;
		});

	}

	const gridGallery = document.getElementById('masonry');
	if(gridGallery){
		imagesLoaded(gridGallery, function(){
			new Masonry(gridGallery , {
				itemSelector: '.grid-item'
			});
			gridGallery.classList.add('active');
		});
	}
	
	$('.voicelist .btn a').on('click', function(){
		$(this).parents('.voicelist__item__data').next('.voicelist__item__hide').stop().slideToggle(200);
		return false;
	});
	
	$('.case__list .more').on('click', function(){
		$(this).next('.case__list__item__hidden').stop().slideToggle(200);
		return false;
	});
};