import Vue from 'vue/dist/vue.min';
import axios from 'axios';

export function vm(){
    const v = new Vue({
        el: '#site-app',
		data: {
			message: false,
			contact: false,
			input: {
				name: '',
				email: '',
				tel: '',
				list: 'お問い合わせ',
				bikou: ''
			},
			send: false,
			alert: false,
		},
		methods: {
			sending: function(){
				let obj = this.input;
				for (const [key, value] of Object.entries(obj)) {
          if(value != ''){
            this.send = true;
          }else{
            this.send = false;
          }
				}
				
        if(obj.email.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)){
            this.send = true;
        }else{
            this.send = false;
        }
				
				if(this.send){
					this.alert = false;
                    const post_url = '/mail/post.php';
                    const $_this = this;
                    axios.post(post_url , $_this.input)
                    .then(response => {
                        if(response.data == 'success'){
                            location.href = "/send/";
							return false;
                        }
                    }).catch(error => {
                        alert('送信に失敗しました');
						return false;
                    });
				}else{
					this.alert = true;
				}
				return false;
			},
		}
    });
};